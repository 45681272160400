import React, { useEffect, useState } from "react"
import './Header.scss'
import * as utilityAction from '../../redux/actions/utilityActions'
import * as userAction from '../../redux/actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom"
import APIConstant from "../../utility/APIConstant"
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import * as clientActions from '../../redux/actions/clientActions'
// import { socket } from "../../utility/Socket"



const Header = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const[isOpened,setSideNavToggle] = useState(false)
   const userInfo = useSelector(state => state.user);
   const [selectedClient, setSelectedClient] = useState("");
   const uniqueCheck =[];

   useEffect(() => {
      if(userInfo?.clients[0]?._id !== undefined){
         dispatch( clientActions.getClientCoursesRequest(userInfo?.clients[0]?._id) )
         let obj={
            clientid:userInfo?.clients[0]?.clientid || userInfo?.clients[0]?.id
           }
         //   dispatch(userAction.setSelectedClientDataReq  (obj))  
         setSelectedClient(userInfo?.clients[0]?._id)
      }
  }, [userInfo?.clients[0]?._id])

   const toggleSideNav = ()=>{
      setSideNavToggle(!isOpened);
      dispatch(utilityAction.toggleSideNav(!isOpened))
   }

   const openSignUpModal = ()=>{
      dispatch(utilityAction.openModal('sign-in'))
   }

   const userLogout = ()=>{
      window.sessionStorage.removeItem('user');
      window.sessionStorage.removeItem('p');
      dispatch(userAction.loggedInUserInfo({}))
      dispatch(userAction.loggedOutUser({}))
      // socket.disconnect()
      if(Object.keys(userInfo.loggedInUser).length > 0){
         navigate('/');
      }
   }
   
   const isNotClient = () =>{
      if(userInfo?.clients.length > 0 && userInfo?.loggedInUser?.role !== APIConstant.CLIENT_ROLE_ID){
         return true;
      }
      else{
         return false;
      }
   }

   const handleChange = (event) => {
     setSelectedClient(event.target.value);
     let obj={
      clientid:userInfo?.clients[event.target.value].clientid
     }
   //   dispatch(userAction.setSelectedClientDataReq  (obj))
     dispatch( clientActions.getClientCoursesRequest(userInfo?.clients[event.target.value]?._id) )
    
   };

   const clintsList= userInfo?.clients.filter((element) => {
   const isDuplicate = uniqueCheck.includes(element?._id); 
   uniqueCheck.push(element?._id);
   if (!isDuplicate) { return element}
   return false
   });

   return <header className="ce-header">
         <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
            {/* <i className="fa fa-bars" title={'toggle side navigation'} onClick={()=>toggleSideNav()}/> */}
            <div className={'container-bars '+ `${isOpened && `change`}` } title={'toggle side navigation'} onClick={()=>toggleSideNav()}>
               <div className="bar1"></div>
               <div className="bar2"></div>
               <div className="bar3"></div>
            </div>
               <a className="navbar-brand" href="#">
                  <img src={'https://assets.contentenablers.com/storefront_stg/imgs/ce_new_logo_skill_comp.webp'} className="brand-logo" alt='brand-logo'/>
               </a>
               {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
               </button> */}
               <div className="navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                     <li className="nav-item" >
                           {/* Signup */}
                     </li>
                  </ul>
                  {Object.keys(userInfo.loggedInUser).length  >  0  && <>
                     { isNotClient() && <>
                     {userInfo?.clients.length === 1 ?
                     <div className="client-name">{userInfo?.clients[0]?.clientname}</div>
                     :
                     <div className="client-name">
                        <FormControl variant="filled" sx={{ m: 1, minWidth: 140 }}>
                           <label>Select Client &nbsp;
                           <select
                              labelid="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={selectedClient}
                              onChange={handleChange}
                           >
                              { Object.keys( clintsList ).map( ( key, index ) => {
                                    return  <option key={ index } name = {userInfo?.clients[ key ]?.clientname} value={key}>{ userInfo?.clients[ key ]?.clientname }</option>
                                 } ) }
                           </select>
                           </label>
                           </FormControl>
                        </div>}</>}
                     <div className="flex-item" title={userInfo?.loggedInUser?.username}>
                        <sub><span className="user-info">{userInfo?.loggedInUser?.username}</span><span className="user-role">&nbsp;&nbsp;({APIConstant.ROLES[userInfo?.loggedInUser?.role]?.name || "Admin"})</span></sub>
                        <i className="fa fa-power-off" onClick={()=>userLogout()}></i>
                     </div></>}
               </div>
            </div>
         </nav>
      </header>
}

export default Header